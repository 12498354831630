import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

import Url from '../constants/Url';

import '../styles/contactForm.css';

const ContactForm = () => {
  const captchaRef = useRef(null);
  const oeuvre = useSelector(state => state.artists.selectedOeuvre);

  const [from, setFrom] = useState("contact@leadev.fr");
  const [to, setTo] = useState("contact@lo4art.com");
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [phone, setPhone] = useState("");
  const [aboNewsletter, setAboNewsletter] = useState(false);
  const [aboVernissage, setAboVernissage] = useState(false);
  const [message, setMessage] = useState("Veuillez renseigner l'ensemble des champs du formulaire.");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState("INIT");

  const [subject, setSubject] = useState(oeuvre !== null ? `Lo4art Gallery - Oeuvre : ${oeuvre.titre_oeuvre}` : "Lo4art Gallery");
  const [text, setText] = useState(oeuvre !== null ? `Bonjour, je suis intéressé(e) par l'acquisition de l'oeuvre : ${oeuvre.titre_oeuvre}, pourriez-vous me recontacter s'il vous plaît ?` : "Bonjour, je suis intéressé(e) par l'acquisition de l'oeuvre ...");

  const sendMailTo = async () => {
    console.log(from + " " + to + " " + subject + " " + text);

    const msg = { to: to, from: from, subject: subject, text: text+" "+email+" "+phone };

    try {
      await axios.post(`${Url.lo4art_Api}contact`, msg);
      console.debug("aboNewsletter :"+aboNewsletter+" aboVernissage :" +aboVernissage)
       await axios.post(`${Url.lo4art_Api}contactPreferences`, {
         email: email,
         aboN: aboNewsletter,
         aboV: aboVernissage
      });
      setStatus("OK");
      setSuccessMessage("Votre message a bien été envoyé");

      // Clear all fields
      setNom("");
      setEmail("");
      setSubject("");
      setPhone("");
      setText("");
      setAboNewsletter(false);
      setAboVernissage(false);
    } catch (err) {
      setStatus("KO");
      setError("Une erreur est intervenue lors de l'envoi de votre message. Veuillez vérifier le formulaire.");
    }

    // Reset message after 5 seconds
    setTimeout(() => {
      setMessage("Veuillez renseigner l'ensemble des champs du formulaire.");
      setSuccessMessage("");
      setError("");
    }, 5000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container lg={{ offset: 3, span: 6, offset: 3 }}>
      <Form>
        <Row className="mb-3">

          <Col>
            {status === "INIT" && successMessage!==""? (
              <div className="alert alert-success" style={{fontFamily:"helveticaMediumExtended",fontSize:16}}>{message}</div>
            ) : status === "OK" && successMessage!==""? (
              <div className="alert alert-success" style={{fontFamily:"helveticaMediumExtended",fontSize:16}}>{successMessage}</div>
            ) : status === "KO" && error!=="" ? (
              <div className="alert alert-danger" style={{fontFamily:"helveticaMediumExtended",fontSize:16}}>{error}</div>
            ) : (<></>)
            }
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridNom">
            <Form.Label className="myformlabel">NOM Prénom</Form.Label>
            <Form.Control type="input" placeholder="Votre identité" className="myforminput" value={nom} onChange={(e) => setNom(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label className="myformlabel">Email</Form.Label>
            <Form.Control type="email" placeholder="Email" className="myforminput" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridSubject">
            <Form.Label className="myformlabel">Sujet</Form.Label>
            <Form.Control type="input" placeholder="Sujet" className="myforminput" value={subject} onChange={(e) => setSubject(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label className="myformlabel">Téléphone</Form.Label>
            <Form.Control type="input" placeholder="Telephone" className="myforminput" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridMessage">
            <Form.Label className="myformlabel">Message</Form.Label>
            <Form.Control as="textarea" row={3} placeholder="Telephone" className="myforminput" value={text} onChange={(e) => setText(e.target.value)} />
          </Form.Group>
        </Row>
        <Row className="mb-3"></Row>
        <Row className="mb-3"></Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridCheckbox">
            <Form.Check className="myforminputtext" type="checkbox" label="Abonnement Newsletter" onChange={(e) => setAboNewsletter(e.target.value==='on'?true:false)}/>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCheckbox">
            <Form.Check className="myforminputtext" type="checkbox" label="Abonnement Vernissage" onChange={(e) => setAboVernissage(e.target.value==='on'?true:false)}/>
          </Form.Group>
          {/* <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} /> */}
        </Row>
        <Button
          type="button"
          variant="primary"
          onClick={sendMailTo}
          className="btnFormSend"
          disabled={!nom || !email || !subject || !phone || !text}
        >
          <span className="btn-envoyer">Envoyer</span>
        </Button>
      </Form>
    </Container>
  );
};

export default ContactForm;

import { createSlice } from "@reduxjs/toolkit";

export const photosSlice = createSlice({
  name: "photos",
  initialState:{
  photos:null,
  title:"Photos",
  displayText:false
  },
  reducers:{
    allPhotos:(state, {payload})=>{
      state.photos = payload;
    },
  },
});

export const { allPhotos } = photosSlice.actions;
export default photosSlice.reducer;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import "../styles/navbar.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

const Navbar =()=> {

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isDropDownArtisteExpanded, setIsDropDownArtisteExpanded] = useState(false);
  const [isDropDownGalerieExpanded, setIsDropDownGalerieExpanded] = useState(false);


  return (

    <nav className="navigation fh5co-nav">

      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
      {/* icon from heroicons.com */}

        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
</svg>
      </button>

      <div  className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
        <ul>

          :<div></div>


          <li>
            <div className="dropdown"><Link to={"/"} onClick={()=>setIsDropDownArtisteExpanded(!isDropDownArtisteExpanded)}>Artistes</Link>
            {isDropDownArtisteExpanded?
              <div className="dropdown-content">
                <Link to={"/Peintres"} onClick={()=>setIsNavExpanded(!isNavExpanded)} >Peintres</Link>
                <Link to={"/Photographes"} onClick={()=>setIsNavExpanded(!isNavExpanded)}>Photographes</Link>
                <Link to={"/Sculpteurs"} onClick={()=>setIsNavExpanded(!isNavExpanded)}>Sculpteurs</Link>
              </div>:<div></div>
            }
            </div>
          </li>

          <li>
            <div className="dropdown"><Link to={"/Galerie"} onClick={()=>setIsDropDownGalerieExpanded(!isDropDownGalerieExpanded)}>Galerie</Link>
            {isDropDownGalerieExpanded?
              <div className="dropdown-content">
                <Link to={"/Galerie"} onClick={()=>setIsNavExpanded(!isNavExpanded)} >À Propos</Link>
                <Link to={"/PhotosExpos"} onClick={()=>setIsNavExpanded(!isNavExpanded)} >Photos Expos</Link>
              </div>:<div></div>
            }
            </div>
          </li>

          <li className="menu-li">
            <Link to={"/Expositions"} onClick={()=>setIsNavExpanded(!isNavExpanded)} >Expositions</Link>
          </li>

          <li className="menu-li">
            <Link to={"/Contact"} onClick={()=>setIsNavExpanded(!isNavExpanded)} >Contact</Link>
          </li>



        </ul>
      </div>
    </nav>



  );
}

export default Navbar;

// <li className="menu-li">
//   <Link to={"/Boutique"} style={{color:"#FF8C00"}} onClick={()=>setIsNavExpanded(!isNavExpanded)} >E-ArtShop</Link>
// </li>

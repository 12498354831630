import { configureStore } from '@reduxjs/toolkit'
import artistsReducer from '../features/artists.slice'
import tokenReducer from '../features/token.slice'
import partnersReducer from '../features/partners.slice'
import photosReducer from '../features/photos.slice'
import expositionsReducer from '../features/expositions.slice'

const reducer = {
  artists:artistsReducer,
  token:tokenReducer,
  partners:partnersReducer,
  photos:photosReducer,
  expos:expositionsReducer,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Url from '../constants/Url';

const Footer = () => {

return(
  <footer id="fh5co-footer" role="contentinfo" style={{backgroundColor:"white"}}>
                  <Container>
                    <Row className="copyright">
                      <Col md={12} className="text-center">
                        <p>
                            <img className="footer-logo" src={`${Url.lo4art_Cdm}/galerie/logo-lo4art.jpeg`}  alt="lo4art" style={{width:"10%",minWidth:"100px",marginBottom:"2%"}} />

                        </p>
                        <ul><li>
                        <a href="https://drive.google.com/open?id=1KtF4_Khx5okaw2ADjQVEcel9tDbJw5Ej1ACgHrhxtAI"
                        target="_blank">Conditions Générales d'Utilisation</a>-<a href="https://drive.google.com/open?id=1_-NkHgDszmJ2oJai3-LBN7QXzduyzripWmpIu7_SfsU"
                        target="_blank">Mentions Légales</a>
                        </li>
                          <li>
                           <a href="https://www.leadev.fr" target="_blank">© 2017-2024 LEADEV - All Rights Reserved</a>
                      </li>  </ul>
                        <ul className="fh5co-social-icons">
                          <li><a href="https://www.facebook.com/galerielo4art" target="_blank"><i className="icon-facebook" /></a></li>
                          <li><a href="https://www.instagram.com/galerielo4art/" target="_blank"><i className="icon-instagram" /></a></li>
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </footer>
)

}

export default Footer;

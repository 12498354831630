import { createSlice } from "@reduxjs/toolkit";

export const tokenSlice = createSlice({
  name: "token",
  initialState:{
  jwt:null
  },
  reducers:{
    get_token:(state, {payload})=>{
      console.log(payload);
      state.jwt = payload;
    },
  },
});

export const { get_token } = tokenSlice.actions;
export default tokenSlice.reducer;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


import Navbar from "./Navbar";
import "../styles/style.css";

const Header = () => {

const props = {
  items:[
    {
      text:'Accueil',
      link:'/'
    },
    {
      text:'Artistes',
      link:'/'
    },
    {
      text:'Galerie',
      link:'/'
    },
    {
      text:'Expositions',
      link:'/'
    },
    {
      text:'Contact',
      link:'/'
    }
  ],
  logo: {
      text: 'Responsive Navbar React'
    },
    style: {
      barStyles: {
        background: '#444'
      },
      sidebarStyles: {
        background: '#222',
        buttonColor: 'white'
      }
    }
}

return(
  <Navbar   className="fh5co-nav fixed-top" style={{color:"black"}} {...props} />

)

}

export default Header;

import { createSlice } from "@reduxjs/toolkit";

export const partnersSlice = createSlice({
  name: "partners",
  initialState:{
  partners:null,
  title:"À propos",
  displayText:true
  },
  reducers:{
    allPartners:(state, {payload})=>{
      state.partners = payload;
    },
  },
});

export const { allPartners } = partnersSlice.actions;
export default partnersSlice.reducer;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Url from '../constants/Url';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//features
import { setArtist, setSelectedArtistId, setOeuvresArtistSelected } from '../features/artists.slice';


const Grid = props => {

  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.token.jwt);


  const updateSelectedArtistID = async() => {
    dispatch(setArtist(props.item));
    const result = await axios.get(`${Url.lo4art_Api}oeuvre/allByArtistId/${props.item.id}`, {
      Accept:"application/json",
    });

    dispatch(setSelectedArtistId(props.item.id));
    dispatch(setOeuvresArtistSelected(result.data.results));
  }

return(
  <div>
  {

    <Col sm={12} md={12} lg={4} className="text-center animate-box">
      <Link to="/Oeuvres"  className="news" href={props.item.id}>
        <div onClick={updateSelectedArtistID} className="news-grid" style={{backgroundImage:`url(${props.item.nomFichier})`, textAlign: 'center'}}>
          <div className="inner">
            <div className="desc">
              <h3>{props.item.nom}</h3>
            </div>
          </div>
        </div>
      </Link>
    </Col>

  }
  </div>
)
}

export default Grid;

import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
//components
import Logosection from '../components/Logosection';
import Container from 'react-bootstrap/Container';
import GalerieContent from '../components/GalerieContent';
//constant
import Url from '../constants/Url';
import { useEffect } from 'react';



const Home = props => {



  return(

    <div>
    {
        props.page === "Home" || props.page === "Peintres" || props.page === "Photographes" || props.page === "Sculpteurs" ?
        <div id="fh5co-news">
          <Container>
          <Link to="/">
          <picture className="row top-line animate-box" id="picture-logo">
          <img src={`${Url.lo4art_Cdm}galerie/logo-lo4art.jpeg`} alt="lo4art" />
          </picture>
          </Link>
          <Logosection data={props.data}/>
          </Container>
        </div>
      :
        props.page === "Galerie" || props.page === "PhotosExpos" || props.page === "Expositions" || props.page === "Oeuvres" || props.page === "Contact" ?
        <div id="fh5co-news">
          <Container>
          <GalerieContent page={props.page} title={props.title} data={props.data}/>
          </Container>
        </div>
      :
        <div></div>
    }
    </div>
  )



}

export default Home;

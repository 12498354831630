import { createSlice } from "@reduxjs/toolkit";

export const artistsSlice = createSlice({
  name: "artists",
  initialState:{
    all:[],
    paintors:[],
    photographers:[],
    sculptors:[],
    artist:{},
    nomFichierOeuvreHomePage:"",
    allArtistsByTypology:[],
    selectedArtistId:null,
    oeuvresArtistSelected:null,
    selectedOeuvre:null,
  },
  reducers:{
    getAll:(state, {payload})=>{
      console.log("getAll, artists.slice.js");
      console.log(payload);
      state.all = payload;
      state.paintors = payload.filter(a=>a.typologie === 1);
      state.photographers = payload.filter(a=>a.typologie === 2);
      state.sculptors = payload.filter(a=>a.typologie === 3);
    },
    homePageOeuvreByArtistId:(state, {payload})=>{
      state.nomFichierOeuvreHomePage = payload;
    },
    allByTypology:(state, {payload})=>{
      state.allArtistsByTypology = payload;
    },
    setSelectedArtistId:(state, {payload})=>{
      state.selectedArtistId = payload;
    },
    setOeuvresArtistSelected:(state, {payload})=>{
      state.oeuvresArtistSelected = payload;
    },
    setArtist:(state, {payload})=>{
      state.artist = payload;
    },
    setSelectedOeuvre:(state, {payload})=>{
      console.log("id selectedOeuvre : "+payload.id)
      state.selectedOeuvre =  payload;
    },
  },
});

export const { getAll, homePageOeuvreByArtistId, allByTypology, setSelectedArtistId, setOeuvresArtistSelected, setArtist, setSelectedOeuvre } = artistsSlice.actions;
export default artistsSlice.reducer;

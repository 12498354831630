import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '../components/Grid';
import { Link } from 'react-router-dom';
import '../styles/oeuvres.css';
import axios from "axios";
import Url from '../constants/Url';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Logosection = props => {

  window.scrollTo(0, 0);

  return(
    <div>
    {
           props.data.map((item)=>{
           return (<Grid key={item.id} item={item} page={props.page} />)
           })
    }
    </div>
  )
}

export default Logosection;

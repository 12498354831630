import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect, Link } from 'react-router-dom';
import Moment from 'react-moment';
//components
import ContactForm from '../components/ContactForm';
import "../styles/gallery.css";
import "../styles/oeuvres.css";
//constant
import Url from '../constants/Url';
//features
import { setSelectedOeuvre } from '../features/artists.slice';

const GalerieContent = props => {


  const dispatch = useDispatch();
  const selectedArtist = useSelector(state=>state.artists.artist);
  const oeuvres = useSelector(state => state.artists.oeuvresArtistSelected);

  let items = [];

  const getArtWork = (a) => {
    console.log("getArtWork");
    //console.log(a);
    let artWork = a;
    console.log(artWork);
    dispatch(setSelectedOeuvre(artWork));
  }


const loadArtWorks = () =>{
//console.log("loadArtWorks******************************************************************");
    for (const id in oeuvres){
      //console.log(selectedArtist.nom);
      //console.log(oeuvres[id].nomFichier);
      items.push(
        <Row key={oeuvres[id].id}>
          <Col md={{offset:2,span:8,offset:2}}>
        <p  className="animate-box center">
          <img
            src={`${Url.lo4art_Cdm}oeuvres-artistes/${selectedArtist.nom.toLowerCase()}/${oeuvres[id].nom_fichier.replaceAll(" ","%20")}`}
            alt={oeuvres[id].technique} className="img-responsive center" style={{maxHeight:"85vh"}}/>
        </p>
        <div className="testimony top-center center">
          <blockquote className="animate-box">

            <p className="center">{oeuvres[id].titre_oeuvre}</p>
            <h4 className="person center">
              <cite className="center">{oeuvres[id].technique}</cite>
              {
                oeuvres[id].montant !==0 ?
                <>
                <br/>
                <cite className="center">{oeuvres[id].montant} €</cite>  
                </>
                :
                <>
                <br/>
                <span>Prix sur demande</span>
                </>
              }
                         
            </h4>
            <Link to="/Contact" className="btn btn-primary center" 
              onClick={()=>getArtWork(oeuvres[id])}>
              Acquérir cette oeuvre
            </Link>
          </blockquote>
        </div>
        </Col>
      </Row>
      );
    }
    return items;
}


  return(
      <div>
      {
        props.page === "Galerie"?
        <div id="fh5co-author">
          <Container>
            <Row className="top-line animate-box">
                  <Col md={{span:6, offset:3, push:2}} className="text-left fh5co-heading">
                      <h1 style={{fontWeight: "bold", display: "none"}}>Galerie d'art	contemporain | Oeuvres d'art moderne | Paris</h1>
                          <h2>{props.title}</h2>
                  </Col>
            </Row>
            <Row>
                  <Col md={{span:8, offset:2}} >
                          <div className="author">
                            <div className="author-inner animate-box"	style={{backgroundImage: `url('${Url.lo4art_Cdm}galerie/Laurent.jpg')`,marginRight:'25px'}}/>
                            <br />
                            <p className="paragraph .desc" >La galerie
                              LO4ART est fondée par Laurent Picard en 2006, directeur
                              artistique actif dans le monde de l’art depuis une vingtaine
                              d’années. LO4ART est le fruit de la combinaison de son métier
                              de publicitaire et de sa passion pour l’art.</p>
                            <br />
                            <p className="paragraph">
                              Galerie itinérante, LO4ART met en place des <Link style={{color:"#cabcab"}}
                                to="/Expositions">expositions prestigieuses pour
                                des artistes de renommée internationale</Link>, tels que Boudro,
                              Dong Oan, Sebastian Copeland, Viktor Polson et Sonya Sklaroff
                              entre autres.
                            </p>
                            <p className="paragraph">
                              Dès janvier 2007, la galerie s'émancipe (artistes, réseau) en
                              s’associant avec les agents d’art New-Yorkais <a style={{color:"#cabcab"}}
                                href="https://www.goartonline.com/" target="_blank">G&amp;O
                                Art</a>, professionnels du marché de l’art international depuis
                              30 ans, avec lesquels la collaboration artistique est depuis
                              très étroite.
                            </p>
                            <p className="paragraph">Durant la décennie de
                              nombreuses expositions à succès se sont déroulées dans des
                              lieux exceptionnels comme certains palaces, le Lutétia, le
                              Westin, des boutiques de luxe, ainsi qu’à l’international,
                              NYC, Chicago, Moscou.</p>
                            <p className="paragraph">L’ambition de la galerie
                              est également de faire du vernissage de chaque exposition un
                              “évènement” différent, une manifestation artistique avec de
                              nombreuses options, Dj résidents, cocktail, concert, captation
                              de la soirée...</p>
                            <p className="paragraph">Les invitations sont
                              envoyées à une mailing liste ciblée : collectionneurs, presse
                              artistique, critiques d’art, célébrités, chefs d’entreprises,
                              avocats, banques, décideurs.</p>
                            <p className="paragraph">LO4ART crée ainsi d’uniques
                              occasions pour les artistes confirmés ou émergents et leur
                              propose de gagner une visibilité conséquente au même titre
                              qu’une vaste notoriété.</p>

                          </div>
                  </Col>
            </Row>
            <Row>
                {props.data.map((item)=>{
                  return(
                    <Col key={item.id} xs={12} sm={6} md={6} lg={4} className="text-center animate-box"
                      style={{marginBottom: "25px"}}>
                      <div className="news" href="#">
                        <a href={item.url} target="_blank">
                          <div className="logos-grid img-responsive"
                            style={{margin:"auto",width:"380px",height:"auto",backgroundImage:`url('${Url.lo4art_Cdm}partenaires/${item.fichier_logo}')`,textAlign:"center"}}>
                            <div className="inner"></div>
                            <h3 style={{display: "none", fontWeight: "bold", fontFamily:"helveticaMediumExtended"}}>Expositions
                              de photos, peintures, sculptures modernes à Paris &amp;
                              New-York</h3>
                          </div>
                        </a>
                      </div>
                    </Col>
                  )
                })}
            </Row>
          </Container>
        </div>
        :
        props.page === "PhotosExpos"?
        <div id="fh5co-author">
          <Container>
            <Row className="top-line animate-box">
                  <Col md={{span:6, offset:3, push:2}} className="text-left fh5co-heading">
                      <h1 style={{fontWeight: "bold", display: "none"}}>Galerie d'art	contemporain | Oeuvres d'art moderne | Paris</h1>
                          <h2>{props.title}</h2>
                  </Col>
            </Row>
            <Row>
            <Col>
            {props.data.map((item)=>{
              return(
                <p key={item.id} className="animate-box work-grid">
                  <img src={`${Url.lo4art_Cdm}galerie/${item.nom_fichier.replaceAll(" ","%20")}`}
                    className="img-responsive partners" alt={`${item.nom_fichier}`}/>
                </p>
              )
            })}
            </Col>
            </Row>
          </Container>
        </div>
        :
        props.page === "Expositions"?
        <div id="fh5co-author">
          <Container>
            <Row className="top-line animate-box">
                  <Col md={{span:6, offset:3, push:2}} className="text-left fh5co-heading">
                      <h1 style={{fontWeight: "bold", display: "none"}}>Galerie d'art	contemporain | Oeuvres d'art moderne | Paris</h1>
                          <h2>{props.title}</h2>
                  </Col>
            </Row>
            <Row>
            <Col xs={12} md={{offset:2,span:6,offset:2}}>
            {props.data.map((item)=>{
              return(
                <p key={item.id} className="animate-box work-grid item">

                      {item.bool_display_date?
                        <div>
                          <hr />
                        <div className="paddingLeft">
                          <p>
                          <h3>
                          <Moment format="YYYY" className="orange">
                          {item.annee}
                          </Moment>
                          </h3>
                          </p>
                          <h3>
                          <span className="bold">{item.titre}</span><br/>
                          <span>{item.adresse_vernissage.charAt(0).toUpperCase()+item.adresse_vernissage.slice(1)}</span>
                          </h3>
                        </div>
                        </div>
                        :
                        <div className="paddingLeft">
                          <h3>
                          <span className="bold">{item.titre }</span><br/>
                          <span>{item.adresse_vernissage.charAt(0).toUpperCase()+item.adresse_vernissage.slice(1)}</span>
                          </h3>
                        </div>
                      }
                </p>)
              })}
              </Col>
              </Row>
              </Container>
          </div>
          :
          props.page === "Oeuvres"?
             selectedArtist === null ?
             <Redirect to={'/'}/>
             :
             <div id="fh5co-author">
              <Container>
                <Row className="top-line">
                  <Col md={{offset:1,span:10,offset:1}}
                    className="fh5co-heading">
                    <h2 className="center">{selectedArtist.nom}</h2>
                  </Col>
                </Row>
                  {
                    loadArtWorks()
                  }
              </Container>
              </div>
          :
          props.page === "Contact"?
          <div id="fh5co-contact">
            <Container>
              <Row className="top-line animate-box">
                    <Col md={{span:6, offset:3, push:2}} className="text-left fh5co-heading">
                        <h1 style={{fontWeight: "bold", display: "none"}}>Galerie d'art	contemporain | Oeuvres d'art moderne | Paris</h1>
                            <h2>{props.title}</h2>
                    </Col>
              </Row>
              <Row>
                    <ContactForm />
              </Row>
            </Container>
				  </div>
          :
          <div></div>
        }
        </div>
  )
}

export default GalerieContent;

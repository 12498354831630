import { createSlice } from "@reduxjs/toolkit";

export const exposSlice = createSlice({
  name: "photos",
  initialState:{
  expos:null,
  title:"Photos",
  displayText:false
  },
  reducers:{
    allExpositions:(state, {payload})=>{
      state.expos = payload;
    },
  },
});

export const { allExpositions } = exposSlice.actions;
export default exposSlice.reducer;
